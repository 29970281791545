import {useTranslation} from 'next-i18next';
import {Page, PageSeo, PageType} from '../components/common/container';
import {Title} from '../components/common/text';
import {useUser} from '../provider/UserProvider';
import LoginChoice from '../components/user/loginChoice';
import {Typography} from '@mui/material';
import {CustomButton} from '../components/common/button';
import {Box} from '@mui/system';
import {useRouter} from 'next/router';
import {GetStaticProps} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

export const getStaticProps: GetStaticProps<{}> = async (context) => {
    const translation = await serverSideTranslations(context.locale, ['common']);
    return {
        props: translation,
        revalidate: 86400, // 24 hours
    };
};

export default function Login() {
    const {t} = useTranslation('common');
    const router = useRouter();
    const {dbUser, logout} = useUser();

    function home() {
        router.push('/');
    }

    return (
        <Page>
            <PageSeo page={PageType.LOGIN} />
            <Title>{t('title.welcome')}</Title>
            {dbUser && (
                <Box
                    display={'flex'}
                    flexDirection="column"
                    alignItems="center"
                    paddingTop={'30px'}
                >
                    <Box>
                        <Typography>{t('info.connected')}</Typography>
                    </Box>
                    <Box marginTop={'30px'}>
                        <CustomButton onClick={home}>{t('action.home')}</CustomButton>
                    </Box>
                </Box>
            )}
            {!dbUser && <LoginChoice />}
        </Page>
    );
}
