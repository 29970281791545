import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useUser} from '../../provider/UserProvider';
import {CustomLink} from '../common/button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Colors} from '../common/colors';
import dynamic from 'next/dynamic';

const LoginForm = dynamic(() => import('../form/loginForm'));
const RegisterForm = dynamic(() => import('../form/registerForm'));

export default function LoginChoice() {
    const {t} = useTranslation('common');
    const {loginByGoogle, sendEmailLink} = useUser();

    const [expanded, setExpanded] = useState<number>(1);

    // const [email, setEmail] = useState<string>('t.baillard@gmail.com');
    // const [emailSent, setEmailSent] = useState<boolean>(false);
    // const {display} = useNotification();

    // function handleChange(event) {
    //     setEmail(event.target.value);
    // }

    // async function sendEmail() {
    //     await sendEmailLink(email);
    //     display(t('display.email_sent') + ' ✈️');
    //     setEmailSent(true);
    // }

    // async function goBack() {
    //     setChoice('');
    //     setEmailSent(false);
    // }

    async function backToBasket() {}

    return (
        <Box
            sx={{
                maxWidth: '853px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '40px',
            }}
        >
            <Accordion
                disableGutters
                expanded={expanded == 1}
                sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: Colors.somber,
                    borderRadius: '8px',
                    boxShadow: 0,
                }}
            >
                <AccordionSummary
                    onClick={() => setExpanded(expanded == 1 ? 0 : 1)}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={t('title.first_visit')}
                    id={t('title.first_visit')}
                    sx={{'& .MuiAccordionSummary-content': {justifyContent: 'center'}}}
                >
                    <Typography sx={{fontSize: '18px', fontWeight: 'bold'}}>
                        {t('title.first_visit')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{padding: '0px'}}>
                    <RegisterForm />
                </AccordionDetails>
            </Accordion>
            <Accordion
                disableGutters
                expanded={expanded == 2}
                sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: Colors.somber,
                    borderRadius: '8px',
                    boxShadow: 0,
                    marginTop: '20px',
                }}
            >
                <AccordionSummary
                    onClick={() => setExpanded(expanded == 2 ? 0 : 2)}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={t('title.other_visit')}
                    id={t('title.other_visit')}
                    sx={{'& .MuiAccordionSummary-content': {justifyContent: 'center'}}}
                >
                    <Typography sx={{fontSize: '18px', fontWeight: 'bold'}}>
                        {t('title.other_visit')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{padding: '0px'}}>
                    <LoginForm />
                </AccordionDetails>
            </Accordion>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                marginTop={'50px'}
            >
                {/* <GoogleIcon
                    onClick={() => loginByGoogle()}
                    sx={{marginRight: '10px', color: Colors.link}}
                /> */}
                {t('title.continue_with')}
                <CustomLink
                    onClick={() => loginByGoogle()}
                    sx={{
                        marginLeft: '5px',
                        color: Colors.link,
                        '& p': {textDecoration: 'none'},
                    }}
                >
                    Google
                </CustomLink>
            </Box>
            {/* <Divider orientation="horizontal" flexItem sx={{marginTop: '30px'}} /> */}
            {/* <Box marginTop={'30px'}>
                <CustomLink onClick={backToBasket}>{t('action.return')}</CustomLink>
            </Box> */}
        </Box>
    );
}

{
    /*
                    <Box
                        display={'flex'}
                        flexDirection="column"
                        alignItems="center"
                        margin={'20px'}
                    >
                        <Box>{t('title.identify_without_password')}</Box>
                        <CustomButton onClick={() => setChoice('email')}>
                            {t('action.login_email')}
                        </CustomButton>
                    </Box> 
                    */
}
{
    /* {choice == 'email' && (
                <>
                    {!emailSent && (
                        <>
                            <Typography>{t('tips.two_steps')}</Typography>
                            <Step number={1} />
                            <Typography>{t('tips.step_one')}</Typography>
                            <Alert severity="warning" sx={{marginTop: '5px'}}>
                                {t('warning.spam')}
                            </Alert>
                            <Step number={2} />
                            <Typography>{t('tips.step_two')}</Typography>
                            <EmailInput
                                sx={{marginTop: '30px'}}
                                value={email}
                                onChange={handleChange}
                                tips={false}
                            />
                            <Box
                                display={'flex'}
                                flexDirection="column"
                                alignItems="center"
                                margin={'20px'}
                            >
                                <CustomButton onClick={sendEmail}>
                                    {t('action.send')}
                                </CustomButton>
                            </Box>
                        </>
                    )}
                    {emailSent && (
                        <>
                            <Typography fontWeight={'bold'}>
                                {t('display.email_sent')}
                            </Typography>
                            <Alert severity="warning" sx={{marginTop: '5px'}}>
                                {t('warning.spam')}
                            </Alert>
                        </>
                    )}
                </>
            )} */
}
